import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("../views/product.vue"),
  },
  {
    path: "/download",
    name: "Download",
    component: () => import("../views/download.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/forum",
    name: "Forum",
    component: () => import("../views/forum.vue"),
  },
  {
    path: "/politica-de-privacidad",
    name: "Politica-de-privacidad",
    component: () => import("@/views/privacy_agreement.vue"),
  },
  {
    path: "/terminos-y-condiciones",
    name: "Terminos-y-condiciones",
    component: () => import("@/views/registration_agreement.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      el: "body",
      top: 0,
    };
  },
});

router.afterEach((to) => {
  // iView.LoadingBar.finish()
  window.scrollTo(0, 0);
  // NProgress.done()
});

export default router;
