<template>
  <header class="header_container fixed" :class="{
    headBGColor:
      scrollPosition > 370 ||
      currentPath.indexOf('/terminos-y-condiciones') != -1 ||
      currentPath.indexOf('/politica-de-privacidad') != -1,
  }">
    <div class="header_box" ref="headNav">
      <div class="logoBox" @click="goTo({ label: 'Home', name: 'Home', to: '/home' })">
        <img alt="" src="../assets/logo.png" style="height:29px" />
      </div>
      <div style="width: 40%">
        <ul class="head_tab" style="width: 100%">
          <li v-for="(item, index) in navs" :key="index" class="ml10" :class="{
            currentActive: item.name === currentTab,
          }" @click="goTo(item)">
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { ref, watch, onMounted, inject, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "FNav",
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const route = useRoute();
    const router = useRouter();
    const routeName = ref("");
    const currentPath = ref("");
    routeName.value = route.name;

    const currentTab = ref("Home");

    const setNavFixed = inject("setNavFixed");

    const scrollPosition = ref(null);
    const updateScroll = () => {
      scrollPosition.value = window.scrollY;
    };
    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
      currentPath.value = route.fullPath;
    });

    const navs = [
      // {
      //   label: "Inicio",
      //   name: "Home",
      //   to: "/home",
      // },
      // {
      //   label: "Préstamo",
      //   name: "Work",
      //   to: "/home",
      // },
      // {
      //   label: "FAQ",
      //   name: "About",
      //   to: "/about",
      // },
      // {
      //   label: "Contáctanos",
      //   name: "Contact",
      //   to: "/home",
      // },
    ];
    watch(
      () => router.currentRoute.value,
      (_new, _old) => {
        console.log(_new);
        console.log(_old);
        routeName.value = _new?.name;
        if (!_old) {
          currentTab.value = "Home";
        }
        if (_old && _new.name !== _old.name && _new.name !== "Home") {
          currentTab.value = _new
            ? _new.name
            : sessionStorage.getItem("CURRENT_TAB");
        }

        // console.log(_new.name);
        // currentTab.value = _new
        //   ? _new.name
        //   : sessionStorage.getItem("CURRENT_TAB");
        currentPath.value = _new?.fullPath;
        console.log(currentTab.value);
        // navs.forEach((item) => {
        //   // console.log(item);
        //   if (item.name === _new) {
        //     currentTab.value = item.label;
        //     console.log(currentTab.value);
        //   }
        // });
        props.fixed && setNavFixed(false);
      },
      {
        immediate: true,
      }
    );
    // const changeTab = (item) => {
    //   currentTab.value = item.label;
    // };
    const goTo = (item) => {
      currentTab.value = item.name;
      console.log(currentTab.value);
      sessionStorage.setItem("CURRENT_TAB", currentTab.value);
      if (route.fullPath !== item.to) {
        router.push({ path: item.to });
      }
      if (item.name === "Home") {
        router.push({ path: item.to });
      }

      if (item.name === "Work") {
        let toElement = document.getElementById("mainContent");
        console.log(toElement);
        if (toElement) {
          setTimeout(() => {
            toElement.scrollIntoView({
              behavior: "smooth", // 平滑过渡
              block: "start",
              inline: "start",
            });
          }, 100);
        }
      }
      if (item.name === "Contact") {
        let toElement = document.getElementById("contentUs");
        if (toElement) {
          setTimeout(() => {
            toElement.scrollIntoView({
              behavior: "smooth", // 平滑过渡
              block: "start",
              inline: "start",
            });
          }, 100);
        }
      }
    };
    function onChange(active) { }

    return {
      routeName,
      currentPath,
      navs,
      currentTab,
      // changeTab,
      scrollPosition,
      goTo,
    };
  },
};
</script>
<style lang="scss" scoped>
@media all and (min-width: 1021px) {
  .headBGColor {
    background: linear-gradient(90.2deg,
        rgba(255, 92, 87, 1) 0.15%,
        rgba(255, 197, 133, 1) 100.06%);
  }

  .header_container {
    transition: padding 0.4s ease;
    will-change: padding;
    position: relative;
    z-index: 100;
    width: 100%;
    min-width: 1020px;
    height: 60px;
    line-height: 60px;

    .header_box {
      width: 1020px;
      height: 60px;
      overflow: hidden;
      align-items: center;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    // background-color: #fff;
    // .padding-top(30px);
    // .padding-left(36px);
    // .padding-right(36px);
    // @media @max768 {
    //   .padding-top(20px);
    //   .padding-left(26px);
    //   .padding-right(26px);
    // }
    .logoBox {
      width: 172px;
      height: 24px;
      line-height: 24px;
      cursor: pointer;

      img {
        width: 172px;
        height: 24px;
      }
    }

    .head_tab {
      list-style: none;
      display: flex;
      justify-content: space-between;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      transition: 0.2s color;
      color: rgba(255, 255, 255, 1);
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;

      &:hover {
        // display: inline-block;
        // width: 78px;
        // height: 35px;
        // text-align: center;
        // line-height: 35px;
        // border-radius: 27px;
        // background: rgba(255, 255, 255, 1);
        color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .currentActive {
      span {
        display: inline-block;
        // width: 78px;
        // height: 35px;
        padding: 0px 10px;
        text-align: center;
        line-height: 35px;
        border-radius: 27px;
        background: rgba(255, 255, 255, 1);
        color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &.white {
      // background-color: #000;
    }

    ::v-deep(.router-link-active) {
      &:hover {
        // display: inline-block;
        // width: 78px;
        // height: 35px;
        // text-align: center;
        // line-height: 35px;
        // border-radius: 27px;
        // background: rgba(255, 255, 255, 1);
        // color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .list {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      font-style: italic;
    }
  }
}

@media all and (max-width: 1020px) {
  .headBGColor {
    background: linear-gradient(90.2deg,
        rgba(255, 92, 87, 1) 0.15%,
        rgba(255, 197, 133, 1) 100.06%);
  }

  .header_container {
    transition: padding 0.4s ease;
    will-change: padding;
    position: relative;
    z-index: 100;
    width: 100%;
    // min-width: 1020px;
    height: 60px;
    line-height: 60px;

    .header_box {
      // width: 1020px;
      width: 100%;
      height: 60px;
      overflow: hidden;
      align-items: center;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }

    // background-color: #fff;
    // .padding-top(30px);
    // .padding-left(36px);
    // .padding-right(36px);
    // @media @max768 {
    //   .padding-top(20px);
    //   .padding-left(26px);
    //   .padding-right(26px);
    // }
    .logoBox {
      width: 172px;
      height: 24px;
      line-height: 24px;
      cursor: pointer;

      img {
        width: 172px;
        height: 24px;
      }
    }

    .head_tab {
      list-style: none;
      display: flex;
      justify-content: space-between;
    }

    &.fixed {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      transition: 0.2s color;
      color: rgba(255, 255, 255, 1);
      font-family: Roboto;
      font-weight: bold;
      font-size: 14px;

      &:hover {
        // display: inline-block;
        // width: 78px;
        // height: 35px;
        // text-align: center;
        // line-height: 35px;
        // border-radius: 27px;
        // background: rgba(255, 255, 255, 1);
        color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }

    .currentActive {
      span {
        display: inline-block;
        // width: 78px;
        // height: 35px;
        padding: 0px 10px;
        text-align: center;
        line-height: 35px;
        border-radius: 27px;
        background: rgba(255, 255, 255, 1);
        color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }

    &.white {
      // background-color: #000;
    }

    ::v-deep(.router-link-active) {
      &:hover {
        // display: inline-block;
        // width: 78px;
        // height: 35px;
        // text-align: center;
        // line-height: 35px;
        // border-radius: 27px;
        // background: rgba(255, 255, 255, 1);
        // color: rgba(255, 110, 96, 1);
        font-family: Roboto;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  .list {
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;

    &:hover {
      font-style: italic;
    }
  }
}</style>